import React from "react";

const Popularity = (d) => {
    let popTextFun = (num, multiplier) => {
        if (num < 80 * multiplier) {
            return "◐○○○"
        } else if (num < 150 * multiplier) {
            return "●○○○"
        } else if (num < 750 * multiplier) {
          return "●◐○○"
        } else if (num < 2000 * multiplier) {
            return "●●○○"
        } else if (num < 5000 * multiplier) {
            return "●●◐○"
        } else if (num < 10000 * multiplier) {
            return "●●●○"
        } else if (num < 25000 * multiplier) {
            return "●●●◐"
        }
        
        return "●●●●"
    }

    let popText = popTextFun(d.utRatings, 75);

    return (
        <>
            <span>POPULARITY:</span> <i className="font-mono">{popText}</i>
        </>
    )
}

export default Popularity;